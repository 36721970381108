<template>
    <div style="margin-bottom: 30vh">
        <div :class="bg"></div>
        <Header :title="$t('mystudy.heading')"></Header>
        <div class="study-container" v-if="userStore.studies.length > 0">
            <StudyTile 
                v-for="study in userStore.studies" 
                    :key="study.study_id" 
                    :studyId="study.study_id"
                    :name="getStudyObject(study.study_id).study.name" 
            />
        </div>

        <!-- @TODO Automatically add studies from the data given by the oeh oauth api -->
        <div class="no-study-container text-center text-blue-4" v-else>
            <h2> {{$t('mystudy.no_study_heading')}} </h2>
            <p>
                {{$t('mystudy.no_study_info')}}
            </p>
            <!-- Browse Studies button -->
            <q-btn size="lg" style="background: #fcf5eb; color: #F5C104;" :label="$t('mystudy.browse_studies')" @click="browseStudies" />
        </div>

        <div class="row justify-center q-gutter-md" v-if="userStore.studies.length > 0" style="margin-top: 2em">

            <!-- <button> ADD </button> -->
            <q-btn size="md"
                style="background: #fcf5eb; color: #F5C104; letter-spacing: 0.1px; font-family: Poppins, sans-serif; font-weight: bold"
                :label="$t('addstudybutton.label')" @click="browseStudies" />
        </div>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import { useUserStore } from '@/store/user.store';
import StudyTile from '@/components/StudyTile.vue';
import { getStudyObject } from '../store/study_logic';

export default {
    components: {
        Header,
        StudyTile
    },
    setup() {
        const userStore = useUserStore();

        return {
            userStore,
            getStudyObject
        };
    },
    async created() {
        if (!this.userStore.loggedIn) {
            this.$router.push('/');
        }

        await this.userStore.updateStudyData();
    },
    methods: {
        browseStudies() {
            this.$router.push("/studies");
        }
    },
    computed: {
        bg() {
            return this.$q.platform.is.desktop ? "bg" : "";
        },
    }
}
</script>



<style scoped>
.no-study-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.bg {
    position: absolute;
    width: 100%;
    height: 130vh;
    background-image: url('../assets/bg.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: -1;
    opacity: 0.15;
    left: -202px;

}

.heading-mystudy {
    display: flex;
    justify-content: center;
}

.title-mystudy {
    font-family: Big Shoulders Display, cursive;
    color: #5bbdf4;
    font-weight: 800;
    font-size: 6vw;
}

.study-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.content-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 4%;
}

.button-mystudy {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-right: 5%;
}


@media screen and (min-width: 320px) and (max-width: 425px) {
    .title-mystudy {
        font-size: 2.8rem;
    }

    .study-container {
        flex-direction: column;
    }

}

@media screen and (min-width: 426px) and (max-width: 768px) {
    .title-mystudy {
        font-size: 3.5rem;
    }

    .study-container {
        flex-direction: column;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .title-mystudy {
        font-size: 5rem;
    }

    .study-container {
        flex-direction: column;
    }
}</style>