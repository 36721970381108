<template>
    <div class="header">
        <div class="title">
            <h1>{{ title }} </h1>
        </div>
    </div>
</template>


<script>
export default {
    name: 'Header',
    props: {
        title: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@400;800&display=swap');

h1 {
    font-weight: bold;
    font-size: 2em;
}

.header {
    display: flex;
    justify-content: center;
}

.title {
    font-family: Big Shoulders Display, cursive;
    color: #5bbdf4;
    font-weight: bold;
    font-size: 6vw;
}


@media screen and (min-width: 320px) and (max-width: 425px) {
    .header h1 {
        font-size: 4.4rem;
    }

}

@media screen and (min-width: 426px) and (max-width: 768px) {
    .header h1 {
        font-size: 4.5rem;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .header h1 {
        font-size: 5rem;
    }
}
</style>