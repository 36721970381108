<template>
    <div style="text-decoration: none; color: inherit" class="class-style">
        <div class="study shadow-1">
            <router-link :to="`/plan/${studyId}`" class="study-title">
                <h6>{{ name }}</h6>
            </router-link>

            <div class="study-info">
                <div class="study-ects">
                    <span>{{ getEctsForStudy() }} /180 ECTS</span>
                </div>
                <div>
                    <q-btn icon="delete" color="negative" @click="removeStudy"></q-btn>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { useUserStore } from '@/store/user.store';
import { useQuasar } from 'quasar';
import { getStudyObject } from '../store/study_logic';

export default {
    props: {
        studyId: String,
    },
    setup(props) {
        const name = getStudyObject(props.studyId).study.abbr;

        return {
            userStore: useUserStore(),
            q: useQuasar(),
            name
        }
    },
    methods: {
        removeStudy() {
            this.userStore.removeStudy(this.studyId, this.q.notify);
        },
        getEctsForStudy() {
            return this.userStore.calcDashboardData(this.studyId).current_ects;
        }
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.study {
    border-radius: 8px;
    width: 24rem;
    height: 12rem;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    background-color: #f9fcff;
    transition: 100ms ease-in;
}

.study:hover {
    background-color: #eff8fd;
}

.study-title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 75%;
    padding: 1em;
    text-decoration: none;
}

.study-title h6 {
    font-size: 2.5em;
    font-family: Staatliches, sans-serif;
    color: #5bbdf4
}

.study-info {
    display: flex;
    justify-content: space-between;
    font-family: Poppins, sans-serif;
    padding-right: 8px;
    padding-left: 8px;
}

.content-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 4%;
}

.button-mystudy {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-right: 5%;
}

button {
    background-color: transparent;
    font-family: 'Staatliches', sans-serif;
    color: #8bcafb;
    font-size: 1.3rem;
    border: 2px solid #5bbdf4;
    border-radius: 5px;
    cursor: pointer;
}

.study-branch,
.study-ects {
    font-family: Poppins, sans-serif;
    font-weight: bold;
    color: #5bbdf4;
    font-size: 1rem;

}

@media screen and (min-width: 320px) and (max-width: 425px) {
    .title-mystudy {
        font-size: 2.8rem;
    }

}

@media screen and (min-width: 426px) and (max-width: 768px) {
    .title-mystudy {
        font-size: 3.5rem;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .title-mystudy {
        font-size: 5rem;
    }
}
</style>
